///////////////////////////////////////////////
///////////////    VARIABLES    ///////////////
///////////////////////////////////////////////

// Colors
$danger: rgba(223, 83, 49, 1);
$dark: rgba(28, 30, 37, 1);
$darken: rgba(0, 0, 0, 0.3);
$darker: rgb(19, 21, 26);
$info: rgb(206, 206, 206);
$light: rgba(226, 226, 226, 1);
$lighten: rgba(255, 255, 255, 0.2);
$muted: rgb(95, 95, 95);
$muter: rgb(70, 70, 70);
$mutest: rgb(48, 48, 48);
$primary-dark:rgb(224, 114, 70);
$primary-darker: rgb(165, 84, 52);
$primary: rgba(255, 154, 96, 1);
$secondary-dark: rgba(44, 52, 63, 1);
$secondary: rgba(151, 197, 195, 1);
$selection: rgba(129, 226, 255, 0.418);
$success: rgba(176, 223, 122, 1);
$tertiary: rgb(40, 33, 51);
$test:rgba(255, 0, 0, 1);
$warning: rgba(241, 211, 110, 1);

// Secret Colors
$dark-secret: rgb(28, 22, 34);
$primary-secret: rgb(164, 224, 85);

// Gradients
$background-gradient: linear-gradient(to bottom, $secondary-dark, rgb(4, 8, 14));
$intro-gradient: linear-gradient(-37deg, $darker, rgb(50, 53, 60));
$work-gradient: linear-gradient(-37deg, $darker, rgb(50, 53, 60));

// Other Variables
$transitionTime: 0.3s;

// bootstrap colors
$theme-colors: (
    danger: $danger,
    dark-secret: $dark-secret,
    dark: $dark,
    darken: $darken,
    info: $info,
    light: $light,
    lighten: $lighten,
    muted: $muted,
    muter: $muter,
    mutest: $mutest,
    primary-dark: $primary-dark,
    primary-darker: $primary-darker,
    primary-secret: $primary-secret,
    primary: $primary,
    secondary-dark: $secondary-dark,
    secondary: $secondary,
    selection: $selection,
    success: $success,
    tertiary: $tertiary,
    test: $test,
    warning: $warning,
);

$shadows: (
    shadow: 0px 0px 10px 0px rgba(0,0,0,0.34)
);

// Border direction map --> uses border-style as the values
$border-dirs: (
    up: solid none none none,
    right: none solid none none,
    down: none none solid none,
    left: none none none solid,
    all: solid
);

// Global styles/default
* {
    font-family: 'Poppins', arial, sans-serif, monospace;
    // color: $light;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    line-height: 2rem;
}

    
// bg color hover mixin
@mixin bg-hover-color($color) {
    &:hover {
        background-color: $color !important;
    }
}

// text color hover mixin
@mixin text-hover-color($color) {
    &:hover {
        color: $color !important;
    }
}

// generic color mixin
@mixin transition {
    transition: background-color $transitionTime, color $transitionTime, border-color $transitionTime
}

// border color mixin
@mixin border-color($color) {
    border-color: $color;
    border-width: 1px;
}

// border color mixin
@mixin border-hover-color($color) {
    &:hover {
        border-color: $color;
    }
}

// Border direction mixin
@mixin border-dir($style) {
    border-style: $style
}

@mixin text-color($color) {
    color: $color;
}

@mixin bg-color($color) {
    background-color: $color;
}
    
// COLOR CLASSES
@each $color-name, $color-value in $theme-colors {
    // BACKGROUND COLOR CHANGE ON HOVER --> eg: bg-hover-test
    .bg-hover-#{$color-name} {
        @include bg-hover-color($color-value);
        @include transition();
    }

    .my-bg-#{$color-name} {
        @include bg-color($color-value);
    }

    .my-text-#{$color-name} {
        @include text-color($color-value);
    }

    // COLORED BORDERS --> eg: border-color-primary
    .my-border-#{$color-name} {
        @include border-color($color-value);
    }

    // COLORED BORDERS --> eg: border-color-primary
    .my-border-hover-#{$color-name} {
        @include border-hover-color($color-value);
        @include transition();
    }

    // TEXT COLOR CHANGE ON HOVER --> eg: text-hover-dark
    .text-hover-#{$color-name} {
        @include text-hover-color($color-value);
        @include transition();
    }
}

// BORDER DIRECTIONS --> eg: border-up
@each $dir, $value in $border-dirs {
    .border-#{$dir} {
        @include border-dir($value)
    }
}

// BORDER WIDTH --> eg: border-width-2
$numberOfClasses: 10;
$borderWidthIncrement: 1px;
@for $i from 0 through $numberOfClasses {
    .border-width-#{$i} {
        border-width: ($borderWidthIncrement * $i) !important;
    }
}

// BORDER RADIUS --> eg: border-radius-2
$numRadiusClasses: 10;
$radiusIncrement: 5px;
@for $i from 0 through $numRadiusClasses {
    .border-radius-#{$i} {
        border-radius: ($radiusIncrement* $i);
    }
}

// Add transition to objects height/width changing
.size-transition {
    transition: height $transitionTime, width $transitionTime;
}

// Add transition to objects height/width changing
.img-transition {
    transition: border-radius ($transitionTime * 1), width $transitionTime;
}

// Use the poppins font
.poppins {
    font-family: 'Poppins', arial, sans-serif, monospace;
}

// Use the ubuntu font
.ubuntu {
    font-family: 'Ubuntu', arial, sans-serif, monospace;
}

// Vertically center an element
.center-vertical {
    top: 50%;
    transform: translateY(-50%);
}


h1, h2, h3, p, hr, span {
    color: $light;
}

// Selection highlight color
::selection {
    background: $selection;
}

// Project tile
.project-tile {
    pointer-events: none;
    display: block;
    max-height: 500px;
    width: 100%;
    margin: auto;
    // max-width: 600px;
    object-fit: cover;
}

.project-img {
    margin: auto;
    width: 100%;
    min-width: 70%;
    min-height: 500px;
    // max-width: 80%;
    object-fit: cover;
}

@media screen and (max-width: 700px) {
    .project-img {
        margin: auto;
        width: 100%;
        min-width: 70%;
        min-height: 200px;
        // max-width: 80%;
        object-fit: cover;
    }
}

.project-description {
    font-size: large;
}

// Project tile
.slide-item {
    transition: transform $transitionTime;
}

body {
    background-image: $background-gradient;
    background-attachment: fixed;
    color: $light;
}

ul {
    list-style: none;
}

p, em {
    font-size: large;
}

.isLink {
    text-decoration: none;
    transition: text-decoration $transitionTime;
}

.isLink:hover {
    text-decoration: underline;
}

// TODO: make this have many variants
.my-shadow {
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.34);
}
.my-shadow-inset {
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.34) inset;
}

.opacity-hover {
    opacity: 50%;
    transition: opacity $transitionTime;
}

.opacity-hover:hover {
    opacity: 80%;
}


.intro {
    background: $intro-gradient;
}

.intro-name {
    font-size: 5rem;
}

$shadow-loc: 0px 45vh 30px black;
.fall-in {
    position: relative;
    top: -10vh;
    animation-name: fall-in;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    // animation-timing-function: cubic-bezier(0.1, 0.6, 0.15, 1);
    animation-timing-function: ease;

    text-shadow: $shadow-loc;
    display: block;
}

@keyframes fall-in {
    10% {
        top: -10vh;
        text-shadow: $shadow-loc;
    }
    100% {
        top: 35vh;
        text-shadow: 0px 0px 0px black;
    }
}

.intro-subtitle {
    font-size: 2rem;
    position: relative;
    opacity: 0%;
    font-family: "Roboto Mono", monospace;

    animation-name: fade-in;
    animation-duration: 6s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.1, 0.6, 0.15, 1);
}

@keyframes fade-in {
    25% {
        opacity: 0%;
    }

    100% {
        opacity: 100%;
}
}

// Bootstrap import
@import "../../../../node_modules/bootstrap/scss/bootstrap";
    